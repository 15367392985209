import {
  Form,
  TextInput,
  Button,
  InlineLoading,
} from "carbon-components-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../store";

export default function LoginForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const context = useAppContext();
  const [busy, setBusy] = useState(false);
  const onSubmit = async (data) => {
    setBusy(true);

    await context.login(data.userName, data.password);

    setBusy(false);
  };

  return (
    <div className="login-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <TextInput
            id="userName"
            labelText="Username"
            placeholder="Username"
            spellCheck={false}
            invalid={!!errors.userName}
            invalidText={errors?.userName?.message}
            {...register("userName", { required: "Required" })}
          />
        </div>
        <div className="mb-5">
          <TextInput
            id="password"
            invalid={!!errors.password}
            type="password"
            labelText="Password"
            spellCheck={false}
            invalidText={errors?.password?.message}
            placeholder="Password"
            {...register("password", { required: "Required" })}
          />
        </div>
        {busy ? (
          <InlineLoading description="Logging In..." />
        ) : (
          <Button tabIndex={0} type="submit">
            Login
          </Button>
        )}
      </Form>
    </div>
  );
}
