import {
  Button,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  NumberInput,
} from "carbon-components-react";
import { Fragment, useMemo, useState } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { Modal } from "carbon-components-react";
import { useGetQueryWithPagination } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { create } from "react-modal-promise";
import InvoicesTable from "./InvoicesPage";
import useApiHandler from "../../../hooks/useApiHandler";
import { useApi } from "../../../store";
import { useWalletRefresh } from "../HomePage";

const openManageRecoveryModal = create(
  ({ isOpen, onReject, onResolve, recoveryboy }) => {
    const [amount, setAmount] = useState(recoveryboy.wallet);
    const [busy, setBusy] = useState(false);
    const refreshWallet = useWalletRefresh();

    const receiveMoneyFromRecoveryBoy = useApiHandler({
      function: useApi().receiveMoneyFromRecoveryBoy,
      config: {
        alerts: true,
      },
    });

    return (
      <Modal
        open={isOpen}
        onRequestClose={onReject}
        hasForm={true}
        primaryButtonDisabled={!amount || busy}
        onRequestSubmit={async () => {
          setBusy(true);
          const { error } = await receiveMoneyFromRecoveryBoy(
            recoveryboy.id,
            amount
          );
          if (!error) {
            refreshWallet();
            onResolve();
          } else setBusy(false);
        }}
        primaryButtonText="Recieve"
        size="xs"
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mb-3 text-center">
            <h1>Rs. {recoveryboy.wallet}</h1>
            <h4>Wallet Balance</h4>
          </div>

          <NumberInput
            style={{ minWidth: "250px" }}
            id="amount"
            labelText="Amount"
            placeholder="Enter Amount"
            min={0}
            max={recoveryboy.wallet}
            invalidText="Amount should be less than or equal to wallet balance"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      </Modal>
    );
  }
);

const openTodayInvoices = create(
  ({ isOpen, onReject, onResolve, recoveryboy }) => {
    return (
      <Modal
        open={isOpen}
        onRequestClose={onReject}
        onRequestSubmit={async () => {
          onResolve();
        }}
        size="xl"
        passiveModal
      >
        <InvoicesTable
          title="Today's Invoices"
          description="Invoice created today"
          showOpen={false}
          where={{
            userId: recoveryboy.id,
            status: undefined,
          }}
        />
      </Modal>
    );
  }
);

export default function RecoveryBoyPage() {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: "recoveryboys",
      queryParams: {
        where: !!searchFieldDebounced
          ? {
              OR: {
                fullName: {
                  startsWith: searchFieldDebounced,
                },
              },
            }
          : undefined,
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      fullName: row.fullName,
      recoveryBoyWalletLimit: `Rs. ${row.recoveryBoyWalletLimit.toLocaleString()}`,
      wallet: `Rs. ${row.wallet.toLocaleString()}`,
      receiveMoneyFromRecoveryBoy: (
        <Button
          kind="ghost"
          onClick={async () => {
            await openManageRecoveryModal({ recoveryboy: row });
            refetch();
          }}
        >
          Recieve Money
        </Button>
      ),
      invoices: (
        <Button
          kind="ghost"
          onClick={async () => {
            await openTodayInvoices({ recoveryboy: row });
          }}
        >
          View Invoices
        </Button>
      ),
    }));
  }, [data, refetch]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            { header: "ID", key: "id" },
            { header: "fullName", key: "fullName" },
            { header: "wallet", key: "wallet" },
            { header: "wallet limit", key: "recoveryBoyWalletLimit" },
            { header: "", key: "receiveMoneyFromRecoveryBoy" },
            { header: "", key: "invoices" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            selectedRows,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title="Recovery Boys"
              className="border"
              description="All recovery boys under you"
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
