import classNames from "classnames";
import React from "react";

export default function Flyout({ children, open = false, dark = false }) {
  return open ? (
    <div className={classNames("flyout", { "flyout-dark": dark })}>
      {children}
    </div>
  ) : null;
}
