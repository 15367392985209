import {
  Button,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  TextInput,
  FormGroup,
  ComboBox,
  OverflowMenu,
  OverflowMenuItem,
} from "carbon-components-react";
import { Fragment, useCallback, useMemo } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";

import { Modal } from "carbon-components-react";
import {
  useCreate,
  useGetQueryWithPagination,
  useUpdate,
} from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { create } from "react-modal-promise";
import { Controller, useForm } from "react-hook-form";

const openCreatePackage = create(
  ({ isOpen, onReject, onResolve, prevData }) => {
    const { register, control, handleSubmit } = useForm({
      defaultValues: prevData,
    });
    const createPackage = useCreate("packages");
    const updatePackage = useUpdate("packages");

    const { data: isps, isLoading } = useGetQueryWithPagination({
      entity: "isps",
      fetchingOptions: {
        enabled: !prevData,
      },
    });

    const onSubmit = async (data) => {
      let error;
      if (prevData) {
        // filter not changed fields
        const filteredData = Object.keys(data).reduce((acc, key) => {
          if (data[key] !== prevData[key] && typeof data[key] != "object") {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        console.log(filteredData);

        if (Object.keys(filteredData).length < 1) {
          return onResolve();
        }

        const { err } = await updatePackage({
          id: prevData.id,
          ...filteredData,
        });

        error = err;
      } else {
        const { err } = await createPackage({
          ...data,
        });
        error = err;
      }
      if (!error) onResolve();
    };

    return (
      <Modal
        open={isOpen}
        onRequestClose={onReject}
        onRequestSubmit={async () => {
          handleSubmit(onSubmit)();
        }}
        modalHeading={prevData ? "Update Package" : "Create Package"}
        primaryButtonText={prevData ? "Update" : "Create"}
        size="xs"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {!prevData && (
            <FormGroup legendText="Company">
              <Controller
                control={control}
                name="internetServiceProviderId"
                render={({ field: { onChange, value } }) =>
                  isLoading ? (
                    "Loading ISPs"
                  ) : (
                    <ComboBox
                      placeholder="Select ISP"
                      items={isps?.result?.rows}
                      selectedItem={isps?.result?.rows?.find(
                        (company) => company.id === value
                      )}
                      itemToString={(item) => item?.name}
                      type="number"
                      itemToElement={(item) => (
                        <div className="d-flex flex-row align-items-center">
                          <img
                            className="me-2"
                            height={15}
                            src={item.logoUrl}
                            alt={item.name}
                          />
                          {item?.name}
                        </div>
                      )}
                      onChange={({ selectedItem }) =>
                        onChange(selectedItem?.id)
                      }
                    />
                  )
                }
              />
            </FormGroup>
          )}

          {!prevData && (
            <div className="mb-3">
              <TextInput
                {...register("speedInMbps", {
                  valueAsNumber: true,
                })}
                labelText="Speed in Mbps"
                placeholder="Enter Speed in Mbps"
                type="number"
              />
            </div>
          )}
          <div className="mb-3">
            <TextInput
              {...register("name", {
                required: "Required",
              })}
              labelText="Package Name"
              placeholder="Enter Package Name"
            />
          </div>
          <TextInput
            {...register("costPrice", {
              required: "Required",
              valueAsNumber: true,
            })}
            labelText="Cost Price"
            placeholder="Enter Cost Price"
            type="number"
          />
        </form>
      </Modal>
    );
  }
);

export default function PackagesPage({
  title = "Packages",
  description = "Manage all the Packages of the system",
  endpoint = "packages",
}) {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: endpoint,
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const handleEditClick = useCallback(
    async (data) => {
      await openCreatePackage({
        isOpen: true,
        onReject: () => {},
        prevData: data,
      });
      refetch();
    },
    [refetch]
  );

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      name: row.name,
      speedInMbps: `${row.speedInMbps} Mbps`,
      costPrice: `Rs. ${Number(row.costPrice).toLocaleString()}`,
      internetServiceProvider: (
        <div className="d-flex flex-row align-items-center">
          <img
            className="me-2"
            height={15}
            src={row.internetServiceProvider.logoUrl}
            alt={row.internetServiceProvider.name}
          />
          {row.internetServiceProvider?.name}
        </div>
      ),
      overflowMenu: (
        <OverflowMenu>
          <OverflowMenuItem
            itemText="Edit"
            onClick={() => {
              handleEditClick(row);
            }}
          />
        </OverflowMenu>
      ),
    }));
  }, [data?.rows, handleEditClick]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            {
              header: "ISP",
              key: "internetServiceProvider",
            },
            { header: "Name", key: "name" },
            { header: "speedInMbps", key: "speedInMbps" },
            { header: "costPrice", key: "costPrice" },
            { header: "Actions", key: "overflowMenu" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title={title}
              className="border"
              description={description}
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    onClick={async () => {
                      await openCreatePackage();
                      refetch();
                    }}
                  >
                    Create new...
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
