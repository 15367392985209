import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../store";

/**
 * Don't use component prop
 */
export default function PrivateRoute({ children, component, ...rest }) {
  let auth = useAppContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          !!children ? (
            children
          ) : (
            component
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
