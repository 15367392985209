import { Modal } from "carbon-components-react";
import { create } from "react-modal-promise";

const AwaitableModal = create(
  ({ isOpen, onResolve, onReject, children, ...props }) => {
    return (
      <Modal
        open={isOpen}
        onRequestSubmit={onResolve}
        onRequestClose={onReject}
        {...props}
      >
        {children}
      </Modal>
    );
  },
  {
    instanceId: "confirmOperation",
  }
);

export default AwaitableModal;
