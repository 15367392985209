import React, { useContext } from "react";
import Api from "../api/Api";

const initialState = {
  api: new Api(),
  login: async () => {},
  logout: async () => {},
  user: undefined,
};

export const AppContext = React.createContext(initialState);
export const useAppContext = () => useContext(AppContext);
export const useApi = () => {
  return useAppContext().api;
};
