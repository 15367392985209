import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  Button,
  FileUploader,
  TextInput,
  Modal,
  RadioButtonGroup,
  RadioButton,
  TextArea,
} from "carbon-components-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { useCreate, useGetQueryWithPagination } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { momentFormatDate, toFormData } from "../../../lib/utils";
import AwaitabledModal from "./../../../lib/AwaitableModal";
import { ReceiptImage } from "../../../components/Image";
import { create } from "react-modal-promise";
import { Controller, useForm } from "react-hook-form";
import { useWalletRefresh } from "../HomePage";

const openCreateSpending = create(({ isOpen, onReject, onResolve }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "EXPENSE",
    },
  });
  const [busy, setBusy] = useState(false);
  const type = watch("type");
  const createSpending = useCreate("spendings");
  const refreshWallet = useWalletRefresh();

  const onSubmit = async (data) => {
    setBusy(true);

    const { error } = await createSpending(toFormData(data), {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (!error) {
      refreshWallet();
      return onResolve();
    }

    setBusy(false);
  };

  useEffect(() => {
    register("receiptImage", {
      required: "Required",
    });
  }, [register, type]);

  return (
    <Modal
      open={isOpen}
      onRequestClose={onReject}
      onRequestSubmit={async () => {
        handleSubmit(onSubmit)();
      }}
      primaryButtonDisabled={busy}
      modalHeading="Record Spending"
      primaryButtonText="Create"
      size="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value, name } }) => {
              return (
                <RadioButtonGroup
                  onChange={(e) => onChange(e)}
                  valueSelected={value}
                  name={name}
                  legendText="Type"
                >
                  <RadioButton labelText="Expense" value="EXPENSE" />
                  <RadioButton labelText="Bank Deposit" value="BANK_DEPOSIT" />
                  <RadioButton labelText="Panel Load" value="PANEL_LOAD" />
                  <RadioButton labelText="Other" value="OTHER" />
                </RadioButtonGroup>
              );
            }}
          />
        </div>
        <div className="mb-2">
          <TextInput
            {...register("amount", {
              required: "Required",
              valueAsNumber: true,
            })}
            labelText="Amount"
            placeholder="Spending amount"
            invalid={errors.amount}
            invalidText={errors.amount?.message}
            type="number"
          />
        </div>
        <div className="mb-2">
          <TextArea
            labelText="Comment (Optional)"
            placeholder="Details for spending."
            {...register("comment")}
          />
        </div>

        <FileUploader
          accept={[".jpg", ".png", ".jpeg", ".bmp"]}
          buttonKind="primary"
          buttonLabel="Add Receipt"
          filenameStatus="edit"
          onChange={async (e) => {
            const file = e.target.files[0];
            setValue("receiptImage", file);
          }}
          iconDescription="Clear Receipt"
        />
        {errors.receiptImage?.type && (
          <div style={{ color: "red" }}>
            {errors.receiptImage?.type === "required" && "Receipt is required."}
          </div>
        )}
      </form>
    </Modal>
  );
});

export default function SpendingsPage() {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: "spendings",
      queryParams: {
        where: !!searchFieldDebounced
          ? {
              OR: {
                comment: {
                  contains: searchFieldDebounced,
                },
              },
            }
          : undefined,
        orderBy: {
          datetime: "desc",
        },
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      datetime: momentFormatDate(row.datetime),
      type: row.type,
      comment: row.comment || "-",
      amount: `Rs. ${Number(row.amount).toLocaleString()}`,
      image: (
        <Button
          kind="ghost"
          onClick={() => {
            AwaitabledModal({
              size: "lg",
              passiveModal: true,
              children: (
                <ReceiptImage spendingId={row.id} style={{ width: "100%" }} />
              ),
            });
          }}
        >
          View Receipt
        </Button>
      ),
    }));
  }, [data?.rows]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            { header: "Timestamp", key: "datetime" },
            { header: "Type", key: "type" },
            { header: "amount", key: "amount" },
            { header: "comment", key: "comment" },
            { header: "", key: "image" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title="Spendings"
              className="border"
              description="All the Spendings of cashier"
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    onClick={() => {
                      openCreateSpending().finally(() => {
                        refetch();
                      });
                    }}
                  >
                    Record Spending
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
