import { Pagination } from "carbon-components-react";
import PropTypes from "prop-types";

function DefaultPagination(props) {
  return (
    <Pagination
      backwardText="Previous page"
      forwardText="Next page"
      itemsPerPageText="Items per page:"
      page={Math.round(props.pagination.skip / props.pagination.take) + 1}
      pageNumberText="Page Number"
      pageSize={props.pagination.take}
      pageSizes={[10, 50, 100, 500, 1000]}
      totalItems={props.count}
      onChange={(data) => {
        props.setPagination((queryOptions) => {
          const copy = { ...queryOptions };
          copy.skip = (data.page - 1) * data.pageSize;
          copy.take = data.pageSize;
          return copy;
        });
      }}
    />
  );
}

DefaultPagination.propTypes = {
  pagination: PropTypes.shape({
    skip: PropTypes.number.isRequired,
    take: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default DefaultPagination;
