import { useState } from "react";
import { useDebounce } from "react-use";

export function useDebounced(watchValue, timeout) {
  const [value, setValue] = useState(watchValue);

  useDebounce(
    () => {
      setValue(watchValue);
    },
    timeout,
    [watchValue]
  );

  return value;
}
