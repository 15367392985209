import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  RadioButton,
  RadioButtonGroup,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextArea,
  TableSelectRow,
  Button,
  Modal,
  DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import * as XLSX from "xlsx";

import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { useGetQueryWithPagination, useUpdate } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { create } from "react-modal-promise";
import { momentFormatDate } from "../../../lib/utils";
import classNames from "classnames";

const entity = "invoices";

// const InvoicesFilter = () => {
//   const { watch, control, setValue } = useFormContext();
//   const [filterType, setFilterType] = useState("today");
//   const createdAt = watch("createdAt");

//   const today = useMemo(
//     () => ({
//       gte: currentDateWithoutTime(),
//     }),
//     []
//   );
//   const yesterday = useMemo(
//     () => ({
//       lt: currentDateWithoutTime(),
//       gte: moment(currentDateWithoutTime()).subtract({ day: 1 }).toDate(),
//     }),
//     []
//   );

//   useEffect(() => {
//     console.log("ran effect", createdAt);
//     if (!createdAt) return;

//     if (createdAt.lt && createdAt.gte) {
//       if (createdAt.lt === yesterday.lt) {
//         if (filterType !== "yesterday") {
//           setFilterType("yesterday");
//         }
//       } else {
//         if (filterType !== "range") {
//           setFilterType("range");
//         }
//       }
//     } else {
//       if (filterType !== "today") {
//         setFilterType("today");
//       }
//     }
//   }, [createdAt]);

//   return (
//     <Fragment>
//       <RadioButtonGroup
//         onChange={(e) => {
//           setFilterType(e);
//           switch (filterType) {
//             case "today":
//               setValue("createdAt", today);
//               break;
//             default:
//               setValue("createdAt", yesterday);
//               break;
//           }
//         }}
//         valueSelected={filterType}
//         legendText="Date Filter"
//       >
//         <RadioButton labelText="Today" value={"today"} />
//         <RadioButton labelText="Yesterday" value={"yesterday"} />
//         <RadioButton labelText="Range" value={"range"} />
//       </RadioButtonGroup>
//       {filterType === "range" && (
//         <div>
//           <DatePicker />
//           <DatePicker />
//         </div>
//       )}
//     </Fragment>
//   );
// };

export default function InvoicesTable({
  where = {},
  title,
  description,
  showOpen = true,
}) {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );
  const [totalData, setTotalData] = useState([]);
  const [dates, setDates] = useState([]);
  // const [filter, setFilter] = useState({});

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity,
      queryParams: {
        where: {
          status: "PENDING",
          loginID: searchFieldDebounced
            ? {
                startsWith: searchFieldDebounced,
              }
            : undefined,
          // ...filter,
          ...where,
        },
        orderBy: {
          createdAt: "desc",
        },
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const records = useMemo(() => {
    const records = data?.rows?.map((row) => ({
      id: row.id,
      loginID: <strong>{row.loginID}</strong>,
      recoveryBoyName: row.user?.fullName,
      amount: `Rs. ${Number(row.amount).toLocaleString()}`,
      createdAt: momentFormatDate(row.createdAt),
      ispLogo: (
        <img
          height="20"
          src={row.package.internetServiceProvider.logoUrl}
          alt={row.package.internetServiceProvider.name}
        />
      ),
      invoiceRequest: row.invoiceRequest,
      package: `${row.package.name} - Rs. ${Number(
        row.package.costPrice
      ).toLocaleString()}`,
      status: row.status,
      didPackageChanged: row.didPackageChanged,
      open: (
        <Button
          kind="ghost"
          onClick={async () => {
            try {
              await create(UpdateInvoiceStatusModal)({
                invoice: row,
                isOpen: true,
              });
            } catch (e) {}
            refetch();
          }}
        >
          Open
        </Button>
      ),
    }));
    setDates([]);
    setTotalData(records);
    return records;
  }, [data, refetch]);
  useEffect(() => {}, [totalData]);
  const handleSearch = (startDate, endDate, data) => {
    const { rows } = data;
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const _data = rows.filter((item) => {
      const createdAt = new Date(item.createdAt);
      return createdAt >= startDate && createdAt <= endDate;
    });
    const records = _data?.map((row) => ({
      id: row.id,
      loginID: <strong>{row.loginID}</strong>,
      recoveryBoyName: row.user?.fullName,
      amount: `Rs. ${Number(row.amount).toLocaleString()}`,
      createdAt: momentFormatDate(row.createdAt),
      ispLogo: (
        <img
          height="20"
          src={row.package.internetServiceProvider.logoUrl}
          alt={row.package.internetServiceProvider.name}
        />
      ),
      invoiceRequest: row.invoiceRequest,
      package: `${row.package.name} - Rs. ${Number(
        row.package.costPrice
      ).toLocaleString()}`,
      status: row.status,
      didPackageChanged: row.didPackageChanged,
      open: (
        <Button
          kind="ghost"
          onClick={async () => {
            try {
              await create(UpdateInvoiceStatusModal)({
                invoice: row,
                isOpen: true,
              });
            } catch (e) {}
            refetch();
          }}
        >
          Open
        </Button>
      ),
    }));
    setTotalData(records);
  };

  const handleExcelDownload = () => {
    let _data = data.rows;
    if (dates.length > 0) {
      let startDate = dates[0];
      let endDate = dates[1];
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      _data = _data.filter((item) => {
        const createdAt = new Date(item.createdAt);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }
    const dataToDownload = _data.map((item) => {
      return {
        TimeStamp: momentFormatDate(item.createdAt),
        RecoveryBoyName: item?.user?.fullName || "No Name",
        Activity: item?.invoiceRequest,
        ISP: item?.package?.internetServiceProvider?.name || "",
        Package: `${item.package.name} - Rs. ${Number(
          item.package.costPrice
        ).toLocaleString()}`,
        LoginID: item.loginID,
        InvoiceStatus: item.status,
        Amount: item.amount,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Create a binary string representation of the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert to Blob and create a download link
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(dataBlob);
    downloadLink.download = `data-${new Date().getTime()}.xlsx`;

    // Append link, trigger the download, then remove the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <>
          <DataTable
            rows={totalData}
            headers={[
              { header: "Timestamp", key: "createdAt" },
              { header: "Recovery Boy Name", key: "recoveryBoyName" },
              { header: "Activity", key: "invoiceRequest" },
              { header: "ISP", key: "ispLogo" },
              { header: "Package", key: "package" },
              { header: "Login ID", key: "loginID" },
              { header: "Invoice Status", key: "status" },
              { header: "Amount", key: "amount" },
              showOpen ? { header: "", key: "open" } : null,
            ].filter((e) => e != null)}
          >
            {({
              rows,
              headers,
              getHeaderProps,
              getTableProps,
              getToolbarProps,
              getSelectionProps,
            }) => (
              <TableContainer
                title={title ?? "Invoices"}
                className="border"
                description={description ?? "All the pending invoices"}
              >
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <DatePicker
                    onChange={(e) => {
                      handleSearch(e[0], e[1], data);
                      setDates(e);
                    }}
                    datePickerType="range"
                  >
                    <DatePickerInput
                      id="date-picker-input-id-start"
                      placeholder="mm/dd/yyyy"
                      labelText="Start date"
                      size="md"
                    />
                    <DatePickerInput
                      id="date-picker-input-id-finish"
                      placeholder="mm/dd/yyyy"
                      labelText="End date"
                      size="md"
                    />
                  </DatePicker>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleExcelDownload();
                    }}
                  >
                    Export
                  </Button>
                </div>

                <TableToolbar {...getToolbarProps()}>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      persistent={true}
                      value={searchField}
                      onChange={(e) => setSearchField(e.target?.value)}
                    />
                    {/* <Button
                    kind="ghost"
                    onClick={async () => {
                      setFilter(
                        await openFilterModal({
                          filter,
                          children: <InvoicesFilter />,
                        })
                      );
                    }}
                  >
                    <Filter16 />
                  </Button> */}
                    <Button onClick={() => refetch()}>Refresh</Button>
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      <TableSelectAll {...getSelectionProps()} />
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      const { didPackageChanged, invoiceRequest } =
                        totalData.find((e) => e.id === row.id) || {};

                      return (
                        <TableRow
                          key={row.id}
                          className={classNames(
                            didPackageChanged && "important",
                            invoiceRequest === "NEW_CONNECTION" &&
                              "important important-green"
                          )}
                        >
                          <TableSelectRow {...getSelectionProps({ row })} />
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <DefaultPagination
                  pagination={pagination}
                  setPagination={setPagination}
                  count={data?.count}
                />
              </TableContainer>
            )}
          </DataTable>
        </>
      )}
    </Fragment>
  );
}

function UpdateInvoiceStatusModal({ onResolve, onReject, isOpen, invoice }) {
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: { status: invoice.status, comment: invoice.comment },
  });
  const editInvoiceApi = useUpdate(entity);
  const [busy, setBusy] = useState(false);
  const status = watch("status");

  const editInvoice = async (data) => {
    setBusy(true);

    const { result, error } = await editInvoiceApi({ ...data, id: invoice.id });

    if (error) {
      setBusy(false);
      return onReject();
    }

    return onResolve(result);
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={() => onReject()}
      onRequestSubmit={async () => {
        await handleSubmit(editInvoice)();
      }}
      primaryButtonDisabled={busy}
      modalHeading="Invoice"
      modalLabel="Update Status"
      primaryButtonText="Update"
      hasForm
    >
      <div className="mb-3">
        <Controller
          control={control}
          name="status"
          render={({ field: { onChange, value } }) => (
            <RadioButtonGroup
              legendText="Invoice Status"
              onChange={onChange}
              valueSelected={value}
            >
              <RadioButton labelText="PENDING" value="PENDING" disabled />
              <RadioButton labelText="❌ INVALID" value="INVALID" />
              <RadioButton labelText="✅ RECHARGED" value="RECHARGED" />
            </RadioButtonGroup>
          )}
        />
      </div>

      {status === "INVALID" && (
        <TextArea
          labelText="Comment (Optional)"
          placeholder="Reason why this inovice is invalid."
          {...register("comment")}
        />
      )}
    </Modal>
  );
}
