import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  Button,
  RadioButtonGroup,
  RadioButton,
} from "carbon-components-react";
import { Fragment, useMemo, useState } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { useGetQueryWithPagination, useUpdate } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import moment from "moment";
import { Checkmark16, Close16, Filter16 } from "@carbon/icons-react";
import { confirmOperation } from "../../../lib/confirmOperation";
import { Controller, useFormContext } from "react-hook-form";
import { openFilterModal } from "../../../components/FilterModal";
import { momentFormatDate } from "../../../lib/utils";
import classNames from "classnames";

const entity = "invoice-recharge-logs";

const InvoiceRechargeLogFilter = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="uploaded"
      render={({ field: { onChange, value } }) => {
        return (
          <RadioButtonGroup
            onChange={(e) => onChange(e)}
            valueSelected={value}
            legendText="Uploaded Filter"
          >
            <RadioButton labelText="All" value={undefined} />
            <RadioButton labelText="Uploaded" value={true} />
            <RadioButton labelText="Not uploaded" value={false} />
          </RadioButtonGroup>
        );
      }}
    />
  );
};

export default function InvoiceEditLogTable() {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );
  const updateInvoiceRechargeLog = useUpdate(entity);
  const [filter, setFilter] = useState({});

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity,
      queryParams: {
        where: {
          invoice: searchFieldDebounced
            ? {
                loginID: {
                  startsWith: searchFieldDebounced,
                },
              }
            : undefined,
          ...filter,
        },
        orderBy: {
          datetime: "desc",
        },
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const records = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      loginID: <strong>{row.invoice.loginID}</strong>,
      datetime: momentFormatDate(row.datetime),
      recoveryBoyName: row.invoice.user?.fullName,
      datetimeFromNow: moment(row.datetime).fromNow(),
      invoiceRequest: row.invoice.invoiceRequest,
      ispLogo: (
        <img
          height="20"
          src={row.invoice.package.internetServiceProvider.logoUrl}
          alt={row.invoice.package.internetServiceProvider.name}
        />
      ),
      package: row.invoice.package?.name,
      amount: `Rs. ${Number(row.invoice.amount).toLocaleString()}`,
      status: row.status,
      uploaded: row.uploaded ? (
        <Checkmark16 color="green" />
      ) : (
        <Close16 color="red" />
      ),
      markAsUploaded: (
        <Button
          kind="ghost"
          disabled={row.uploaded}
          onClick={async () => {
            if (
              await confirmOperation(
                "Are you sure you want to mark this recharge log as uploaded?"
              )
            ) {
              await updateInvoiceRechargeLog({
                id: row.id,
                uploaded: true,
              });
              refetch();
            }
          }}
        >
          Mark as Uploaded
        </Button>
      ),
    }));
  }, [data, refetch, updateInvoiceRechargeLog]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={records}
          headers={[
            { header: "Timestamp", key: "datetime" },
            { header: "ISP", key: "ispLogo" },
            { header: "Recovery Boy Name", key: "recoveryBoyName" },
            { header: "Activity", key: "invoiceRequest" },
            { header: "Package", key: "package" },
            { header: "Amount", key: "amount" },
            { header: "Login ID", key: "loginID" },
            { header: "Uploaded?", key: "uploaded" },
            { header: "pastTime", key: "datetimeFromNow" },
            { header: "", key: "markAsUploaded" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title={"Invoice Recharged Logs"}
              className="border"
              description={"All the logs of invoices"}
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    kind="ghost"
                    onClick={async () => {
                      setFilter(
                        await openFilterModal({
                          filter,
                          children: <InvoiceRechargeLogFilter />,
                        })
                      );
                    }}
                  >
                    <Filter16 />
                  </Button>
                  <Button onClick={() => refetch()}>Refresh</Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    const rec = data.rows?.find((e) => e.id === row.id) || {};

                    return (
                      <TableRow
                        key={row.id}
                        className={classNames(
                          rec.invoice.amount < rec.invoice.package?.costPrice &&
                            "important important-orange",
                          rec.invoice.invoiceRequest === "NEW_CONNECTION" &&
                            "important important-green"
                        )}
                      >
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
