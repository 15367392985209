import { ToastNotification } from "carbon-components-react";

export default function Alert({ message, close, id, options }) {
  return (
    <ToastNotification
      closeOnEscape
      onCloseButtonClick={(_) => close()}
      key={id}
      kind={options.type}
      subtitle={<span>{message}</span>}
      timeout={0}
      title={options.type.toString().toUpperCase()}
      caption={""}
      style={{ zIndex: 10000 }}
    />
  );
}
