import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  Button,
  DatePicker,
  DatePickerInput,
  RadioButtonGroup,
  RadioButton,
  TextInput,
  Select,
  SelectItem,
} from "carbon-components-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { useGetQueryWithPagination } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { momentFormatDate, saveBlob } from "../../../lib/utils";
import { Controller, useFormContext } from "react-hook-form";
import { openFilterModal } from "../../../components/FilterModal";
import { Filter16, Export16 } from "@carbon/icons-react";
import moment from "moment";
import useApiHandler from "../../../hooks/useApiHandler";
import { useApi } from "../../../store";
import AwaitabledModal from "./../../../lib/AwaitableModal";
import { ReceiptImage } from "../../../components/Image";

function TransactionFilter() {
  const { control, register, setValue, getValues } = useFormContext();
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [activeCashier, setActiveCahier] = useState("");
  const [companyCashiers, setCompanyCashier] = useState([]);

  useEffect(() => {
    register("spending.type");
    register("NOT");
    register("cashier");
  }, [register]);

  useEffect(() => {
    if (activeCashier !== "") {
      setValue("cashier", activeCashier);
      setValue("spending.type", activityTypeValue);
      if (activityTypeValue !== undefined && activityTypeValue !== null) {
        setValue("spending.type", activityTypeValue);
        setValue("NOT", undefined);
      } else {
        console.log("i am in else");
        const { NOT, spending } = getValues();
        console.log({ spending: spending.type, NOT });
        if (spending) {
          console.log("s");
          setActivityTypeValue(spending.type);
        }
        if (NOT) {
          console.log("n");

          setActivityTypeValue("CASH_RECEIVED");
        }
        console.log({ spending: spending.type, NOT, s: spending });

        if (!NOT && !spending.type) {
          console.log("i am in here");
          setValue("NOT", undefined);
          setValue("spending", undefined);
        }
      }
    }
    if (activityTypeValue === "CASH_RECEIVED") {
      setValue("NOT", {
        recoveryBoy: null,
      });
      setValue("spending", undefined);
    } else if (activityTypeValue !== undefined && activityTypeValue !== null) {
      setValue("spending.type", activityTypeValue);
      setValue("NOT", undefined);
    } else {
      const { NOT, spending } = getValues();
      if (spending) {
        setActivityTypeValue(spending.type);
      }
      if (NOT) {
        setActivityTypeValue("CASH_RECEIVED");
      }
      if (!NOT && !spending) {
        setValue("NOT", undefined);
        setValue("spending", undefined);
      }
    }
  }, [activityTypeValue, activeCashier, setValue, getValues]);
  const cashiers = useApiHandler({
    function: useApi().getCompanyCashierForGM,
  });
  const getCashiers = async () => {
    const cashiersList = await cashiers();
    const _cashierList = cashiersList.result.result.rows;
    console.log({ cashiersList, _cashierList });
    setCompanyCashier(_cashierList || []);
  };

  useEffect(() => {
    getCashiers();
  }, []);

  return (
    <Fragment>
      <TextInput
        labelText="Search by Description"
        {...register("description.contains")}
      />
      <div className="mb-3">
        <RadioButtonGroup
          onChange={(value) => {
            setActivityTypeValue(value);
          }}
          valueSelected={activityTypeValue}
          orientation="vertical"
          legendText="Activity Type"
        >
          <RadioButton labelText="All" value={null} />
          <RadioButton labelText="Expense" value="EXPENSE" />
          <RadioButton labelText="Bank Deposit" value="BANK_DEPOSIT" />
          <RadioButton labelText="Panel Load" value="PANEL_LOAD" />
          <RadioButton labelText="Cash received" value="CASH_RECEIVED" />
        </RadioButtonGroup>
        <Select
          id="select-1"
          value={activeCashier}
          onChange={(e) => {
            console.log(e.target.value);
            setActiveCahier(e.target.value);
          }}
          invalidText="A valid value is required"
        >
          <SelectItem value="" text="Choose A Cashier" />
          {companyCashiers.map((cashier) => {
            return (
              <SelectItem
                text={cashier.fullName}
                key={cashier.id}
                value={cashier.id}
              />
            );
          })}
        </Select>
      </div>
      <Controller
        name="datetime"
        control={control}
        render={({ field: { name, onChange, value, ref, onBlur } }) => {
          return (
            <DatePicker
              datePickerType="range"
              dateFormat="d-m-Y"
              onBlur={onBlur}
              onChange={(data) => {
                console.log(data);
                onChange({
                  gte: moment(data[0]).subtract({ day: 1 }).toDate(),
                  lte: moment(data[1]).add({ day: 1 }).toDate(),
                });
              }}
            >
              <DatePickerInput
                id="date-picker-input-id-start"
                placeholder="mm/dd/yyyy"
                labelText="Start date"
                value={
                  value?.gte
                    ? moment(value.gte).add({ day: 1 }).format("DD/MM/YYYY")
                    : undefined
                }
              />
              <DatePickerInput
                id="date-picker-input-id-finish"
                placeholder="mm/dd/yyyy"
                labelText="End date"
                value={
                  value?.lte
                    ? moment(value.lte)
                        .subtract({ day: 1 })
                        .format("DD/MM/YYYY")
                    : undefined
                }
              />
            </DatePicker>
          );
        }}
      />
    </Fragment>
  );
}

export default function TransactionLog() {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );
  const [filter, setFilter] = useState({});

  const downloadPdf = useApiHandler({
    function: useApi().getPdfTransactions,
    config: {
      alerts: true,
    },
  });

  const { data, isLoading, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: "transactions",
      queryParams: {
        where: {
          ...filter,
          ...(!!searchFieldDebounced
            ? {
                OR: {
                  recoveryBoy: {
                    fullName: { startsWith: searchFieldDebounced },
                  },
                },
              }
            : {}),
        },
        orderBy: {
          datetime: "desc",
        },
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      datetime: momentFormatDate(row.datetime),
      activity: row.spending
        ? row.spending.type
        : row.recoveryBoy && "CASH_RECIEVED",
      description: row.description,
      recoveryBoyName: row.recoveryBoy?.fullName ?? "N/A",
      cashierName: row.user?.fullName ?? "N/A",
      wallet: (
        <span style={{ color: row.inAmount === 0 ? "red" : "green" }}>
          {row.inAmount !== 0
            ? `+ Rs. ${Number(row.inAmount).toLocaleString()}`
            : `- Rs. ${Number(row.outAmount).toLocaleString()}`}
        </span>
      ),
      image: row.spending && (
        <Button
          kind="ghost"
          onClick={() => {
            AwaitabledModal({
              size: "lg",
              passiveModal: true,
              children: (
                <ReceiptImage
                  spendingId={row.spending.id}
                  style={{ width: "100%" }}
                />
              ),
            });
          }}
        >
          View Receipt
        </Button>
      ),
    }));
  }, [data]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            { header: "Timestamp", key: "datetime" },
            { header: "Activity", key: "activity" },
            { header: "Recovery Boy", key: "recoveryBoyName" },
            { header: "Cashier", key: "cashierName" },
            { header: "Wallet", key: "wallet" },
            { header: "Descriptions", key: "description" },
            { header: "", key: "image" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title="Transactions"
              className="border"
              description="All the transactions of cashier"
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    placeholder="Search by Recovery Man"
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    kind="ghost"
                    onClick={async () => {
                      console.log({filter})
                      if(filter.cashier ){
                        filter.userId=Number(filter.cashier);
                        delete filter.cashier;
                      }
                      const { result: pdfBlob } = await downloadPdf(filter);
                      if (pdfBlob)
                        saveBlob(
                          pdfBlob,
                          `transactions-${
                            filter?.datetime?.gte
                              ? momentFormatDate(filter.datetime.gte)
                              : "start"
                          }~${
                            filter?.datetime?.lte
                              ? momentFormatDate(filter.datetime.lte)
                              : "end"
                          }.pdf`
                        );
                    }}
                  >
                    <Export16 className="me-3" />
                    Export
                  </Button>
                  <Button
                    kind="ghost"
                    onClick={async () => {
                      const filterNew = await openFilterModal({
                        filter,
                        children: <TransactionFilter />,
                      });

                      setFilter(filterNew);
                    }}
                  >
                    <Filter16 className="me-2" />
                    Filter
                  </Button>
                  {filter && Object.keys(filter).length > 0 && (
                    <Button
                      kind="danger"
                      onClick={async () => {
                        setFilter({});
                      }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
