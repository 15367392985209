import {
  Button,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  TextInput,
} from "carbon-components-react";
import { Fragment, useMemo } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { Modal } from "carbon-components-react";
import { useCreate, useGetQueryWithPagination } from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { create } from "react-modal-promise";
import { useForm } from "react-hook-form";

const openCreateCompany = create(({ isOpen, onReject, onResolve }) => {
  const { register, handleSubmit } = useForm({});
  const createCompany = useCreate("companies");

  const onSubmit = async (data) => {
    console.log(data);
    const { error } = await createCompany(data);
    if (!error) onResolve();
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onReject}
      onRequestSubmit={async () => {
        handleSubmit(onSubmit)();
      }}
      modalHeading="Create Company"
      primaryButtonText="Create"
      size="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <TextInput
            {...register("name", { required: "Required" })}
            labelText="Company Name"
            placeholder="Enter company Name"
          />
        </div>
        <TextInput
          {...register("logoUrl", { required: "Required" })}
          labelText="Logo Url"
          placeholder="Enter Logo Url"
        />
      </form>
    </Modal>
  );
});

export default function CompaniesPage({
  title = "Companies",
  description = "Manage all the companies of the system",
  endpoint = "companies",
}) {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: endpoint,
      queryParams: {
        where: !!searchFieldDebounced
          ? {
              OR: {
                fullName: {
                  startsWith: searchFieldDebounced,
                },
              },
            }
          : undefined,
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }, [data]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            { header: "ID", key: "id" },
            { header: "Name", key: "name" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getTableProps,
            getToolbarProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title={title}
              className="border"
              description={description}
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    onClick={async () => {
                      await openCreateCompany();
                      refetch();
                    }}
                  >
                    Create new...
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
