// Third Party Libs
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider as AlertProvider, transitions, positions } from 'react-alert';
import { ReactQueryDevtools } from "react-query/devtools";
import ModalContainer from "react-modal-promise";

// Pages
import HomePage, { HomePagePath } from "./pages/Home";
import Login, { LoginPath } from "./pages/Login";
import Page404 from "./pages/Page404";

// Components
import PrivateRoute from "./components/PrivateRoute";

// SCSS
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import Alert from "./components/Alert/Alert";
import GlobalProvider from "./store/GlobalProvider";
import { useEffect, useState } from "react";

const alertOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 100000,
    pointerEvents: "all",
  },
};

const App = () => {
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    setIsDev(process.env.NODE_ENV === "development");
  }, []);

  return (
    <QueryClientProvider client={new QueryClient()}>
      <AlertProvider template={Alert} {...alertOptions}>
        <Router>
          <GlobalProvider>
            {isDev && <ReactQueryDevtools />}
            <Switch>
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path={LoginPath} component={Login} />
              <PrivateRoute path={HomePagePath}>
                <HomePage />
              </PrivateRoute>
              <Route path="*" component={Page404} />
            </Switch>
            <ModalContainer isAppendIntances={true} />
          </GlobalProvider>
        </Router>
      </AlertProvider>
    </QueryClientProvider>
  );
};

export default App;
