import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  Content,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SideNavMenuItem,
  SideNavDetails,
  Button,
  HeaderContainer,
  HeaderMenuButton,
} from "carbon-components-react";
import { Switch, Route, Redirect, Link, useLocation } from "react-router-dom";
import UserProfileHeaderAction from "../../components/UserProfileHeaderAction/UserProfileHeaderAction";
import { useAppContext } from "../../store";
import InvoicePage from "./Partials/InvoicesPage";
import RecoveryBoyPage from "./Partials/RecoveryBoy";
import React, { useContext, useMemo } from "react";
import InvoiceEditLogTable from "./Partials/InvoicesLogs";
import TransactionLog from "./Partials/TransactionLogs";
import PackagesPage from "./Partials/PackagesPage";
import UsersPage from "./Partials/UsersPage";
import SpendingsPage from "./Partials/SpendingsPage";
import { useGetQueryWithPagination } from "../../hooks/useCrud";
import CompaniesPage from "./Partials/CompaniesPage";
import { Renew16 } from "@carbon/icons-react";

export const HomePagePath = "/";

const routes = {
  "/home/invoices": {
    component: InvoicePage,
    name: "Invoices",
    roles: ["OPERATOR"],
  },
  "/home/invoices-recharge-logs": {
    component: InvoiceEditLogTable,
    name: "Recharge Logs",
    roles: ["OPERATOR"],
  },
  "/home/recoveryboys": {
    component: RecoveryBoyPage,
    name: "Recovery Boys",
    roles: ["CASHIER"],
  },
  "/home/users": {
    component: UsersPage,
    name: "Users",
    roles: ["GENERAL_MANAGER", "ADMIN"],
  },
  "/home/companies": {
    component: CompaniesPage,
    name: "Companies",
    roles: ["ADMIN"],
  },
  "/home/packages": {
    component: PackagesPage,
    name: "Packages",
    roles: ["GENERAL_MANAGER"],
  },
  "/home/transactions": {
    component: TransactionLog,
    name: "Transaction Log",
    roles: ["CASHIER", "GENERAL_MANAGER"],
  },
  "/home/spendings": {
    component: SpendingsPage,
    name: "Spendings",
    roles: ["CASHIER"],
  },
};

const walletContext = React.createContext({
  refetch: () => {
    console.log("DEFAULT_FETCH_CALLED");
  },
});

export const useWalletRefresh = () => {
  return useContext(walletContext).refetch;
};

export default function HomePage() {
  const location = useLocation();
  const { user } = useAppContext();
  const [expanded, setExapanded] = React.useState(true);

  const { data, refetch, isFetching, isLoading } = useGetQueryWithPagination({
    entity: "wallet",
    fetchingOptions: {
      enabled: user.role === "CASHIER",
      refetchInterval: 15000,
      refetchIntervalInBackground: true,
    },
  });

  const walletContextProviderValue = useMemo(() => {
    return {
      refetch,
    };
  }, [refetch]);

  const allowedRoutes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(routes).filter(([key, route]) =>
          route.roles.includes(user.role)
        )
      ),
    [user.role]
  );

  return (
    <div className="container">
      <HeaderContainer
        isSideNavExpanded={expanded}
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <>
            <Header aria-label="NetWala Admin">
              <HeaderMenuButton
                isCollapsible
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
              />
              <HeaderName href="#" prefix="Netelsoft">
                RMAN
              </HeaderName>
              <HeaderGlobalBar>
                <UserProfileHeaderAction />
              </HeaderGlobalBar>
            </Header>
            <SideNav
              className="border-end"
              isFixedNav
              expanded={isSideNavExpanded}
            >
              {user.role === "CASHIER" && (
                <SideNavDetails
                  title={`Rs. ${
                    data?.result?.amount === undefined
                      ? "-"
                      : Number(data?.result?.amount).toLocaleString()
                  }`}
                  className="override-side-bar-detials"
                >
                  <div
                    className="d-flex flew-row justify-content-center align-items-center"
                    style={{ color: (isLoading || isFetching) && "orange" }}
                  >
                    Amount in Wallet
                    <Button
                      renderIcon={Renew16}
                      onClick={() => refetch()}
                      iconDescription="Refresh"
                      size="small"
                      kind="ghost"
                      hasIconOnly
                    />
                  </div>
                </SideNavDetails>
              )}
              <SideNavItems>
                {Object.entries(allowedRoutes).map(([route, data]) => (
                  <SideNavMenu
                    title={data.name}
                    isActive={location.pathname.startsWith(route)}
                    expanded={location.pathname.startsWith(route)}
                  >
                    <Link to={route} style={{ textDecoration: "none" }}>
                      <SideNavMenuItem isActive={location.pathname === route}>
                        Manage
                      </SideNavMenuItem>
                    </Link>
                  </SideNavMenu>
                ))}
              </SideNavItems>
            </SideNav>
          </>
        )}
      />
      <Content className="content">
        <walletContext.Provider value={walletContextProviderValue}>
          <Switch>
            <Route path="/home" exact>
              <Redirect to={Object.keys(allowedRoutes)[0]} />
            </Route>
            {Object.entries(allowedRoutes).map(([route, data]) => {
              const Component = data.component;

              return (
                <Route path={route}>
                  <Component />
                </Route>
              );
            })}
          </Switch>
        </walletContext.Provider>
      </Content>
    </div>
  );
}
