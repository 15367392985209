import { useQuery } from "react-query";
import { useApi } from "../store";
import { useMemo, useState } from "react";
import useApiHandler from "./useApiHandler";
import axios from "axios";

export function useCreate(entity) {
  const createFunc = useApiHandler({
    function: useApi().create,
    config: { alerts: true },
  });

  return async (data, options) => {
    return createFunc(entity, data, options);
  };
}

export function useDelete(entity) {
  const deleteFunc = useApiHandler({
    function: useApi().delete,
    config: { alerts: true },
  });

  return async (ids) => deleteFunc(entity, ids);
}

export function useUpdate(entity) {
  const updateFunc = useApiHandler({
    function: useApi().update,
    config: { alerts: true },
  });

  return async (data) => updateFunc(entity, data);
}

export function useGet(entity) {
  const readFunc = useApiHandler({
    function: useApi().get,
    config: { throwError: true },
  });

  return async (params, options) => readFunc(entity, params, options);
}

export function useAssign(entity, withEntity) {
  const assignFunc = useApiHandler({
    function: useApi().assign,
    config: { alerts: true },
  });

  return async (id, ids) => assignFunc(entity, id, withEntity, ids);
}

export function useDeAssign(entity, withEntity) {
  const deAssignFunc = useApiHandler({
    function: useApi().deassign,
    config: { alerts: true },
  });

  return async (id, ids) => deAssignFunc(entity, id, withEntity, ids);
}

export function useGetQueryWithPagination({
  entity,
  fetchingOptions,
  queryParams,
  defaultPagination,
}) {
  const get = useGet(entity);
  const [pagination, setPagination] = useState(
    !defaultPagination
      ? {
          take: 10,
          skip: 0,
        }
      : defaultPagination
  );

  const queryKey = useMemo(
    () => [entity, pagination, queryParams],
    [entity, pagination, queryParams]
  );

  const queryReturn = useQuery(
    queryKey,
    async () => {
      const source = axios.CancelToken.source();
      const promise = get(
        { ...queryParams, ...pagination },
        { cancelToken: source.token }
      );

      promise.cancel = () => source.cancel();

      return promise;
    },
    fetchingOptions
  );

  return {
    ...queryReturn,
    queryKey,
    pagination,
    setPagination,
  };
}
