import { useApi } from "../store";
import React, { Fragment } from "react";
import { Button } from "carbon-components-react";
import { saveBlob } from "../lib/utils";

/* eslint-disable jsx-a11y/alt-text */
export default function Image({ blob, ...props }) {
  const url = URL.createObjectURL(blob);

  return <img src={url} {...props} />;
}

export function ReceiptImage({ spendingId, ...props }) {
  const api = useApi();
  const [loading, setLoading] = React.useState(true);
  const [imageString, setImageString] = React.useState(null);

  React.useEffect(() => {
    api
      .getReceiptImage(spendingId)
      .then((blob) => {
        return setImageString(blob);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [api, spendingId]);

  return imageString == null ? (
    <>{loading ? "Loading" : "No image found"}</>
  ) : (
    <Fragment>
      <Image blob={imageString} {...props} />
      <Button
        onClick={() => {
          saveBlob(imageString, `receipt-${spendingId}.png`);
        }}
      >
        Download
      </Button>
    </Fragment>
  );
}
