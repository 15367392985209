import Flyout from '../Flyout';

import { UserAvatar20 } from '@carbon/icons-react';
import {
  HeaderGlobalAction,
  Button,
  Modal,
  TextInput,
  Form,
} from "carbon-components-react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useApi, useAppContext } from "../../store";
import { create } from "react-modal-promise";
import { useForm } from "react-hook-form";
import useApiHandler from "../../hooks/useApiHandler";

export const openChangePasswordModal = create(
  ({ isOpen, onReject, onResolve, id }) => {
    const { register, handleSubmit } = useForm();

    const changePassword = useApiHandler({
      function: useApi().changePassword,
      config: { alerts: true },
    });

    const formSubmittedHandler = async (data) => {
      return changePassword(data, id);
    };

    return (
      <Modal
        open={isOpen}
        onRequestClose={onReject}
        onRequestSubmit={async () => {
          await handleSubmit(formSubmittedHandler)();
          onResolve();
        }}
        modalHeading="Change Password"
        primaryButtonText="Change password"
      >
        <Form onSubmit={handleSubmit(formSubmittedHandler)}>
          {!id && (
            <div className="mb-3">
              <TextInput
                placeholder="Your current Password"
                type="password"
                labelText="Current Password"
                {...register("curPassword", { required: "Required" })}
              />
            </div>
          )}
          <div className="mb-3">
            <TextInput
              placeholder="New password"
              type="password"
              labelText="New Password"
              {...register("newPassword", { required: "Required" })}
            />
          </div>
          <TextInput
            placeholder="Confirm new password"
            type="password"
            labelText="Confirm New Password"
            {...register("confirmNewPassword", { required: "Required" })}
          />
        </Form>
      </Modal>
    );
  }
);

export default function UserProfileHeaderAction() {
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const appContext = useAppContext();
  const history = useHistory();

  const logout = () => {
    appContext.logout().then(() => {
      history.push("/login");
    });
  };

  const handleClickOnChangePassword = () => {
    openChangePasswordModal();
  };

  return (
    <div className="flyout-container">
      <HeaderGlobalAction
        title={"User Options"}
        onClick={() => setFlyoutOpen((state) => !state)}
      >
        <UserAvatar20 />
      </HeaderGlobalAction>
      <Flyout open={flyoutOpen} dark>
        <div className="user-profile-box">
          <div className="user-profile-box__detail">
            <h4>{appContext.user?.userName}</h4>
            <p>{appContext.user?.role}</p>
          </div>
          <Button
            className="user-profile-box__button"
            kind="secondary bg-dark"
            onClick={handleClickOnChangePassword}
          >
            Change Password
          </Button>
          <Button
            className="user-profile-box__button"
            kind="secondary"
            onClick={logout}
          >
            Logout
          </Button>
        </div>
      </Flyout>
    </div>
  );
}
