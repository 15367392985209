import {
  Button,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectRow,
  TextInput,
  Toggle,
  ComboBox,
  RadioButtonGroup,
  RadioButton,
  FormGroup,
} from "carbon-components-react";
import ToggleSingleLine from "./../../../components/ToggleSingleLine/ToggleSingleLine";
import { Fragment, useMemo } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { Modal } from "carbon-components-react";
import {
  useCreate,
  useGetQueryWithPagination,
  useUpdate,
} from "../../../hooks/useCrud";
import DefaultPagination from "../../../components/DefaultPagination";
import { create } from "react-modal-promise";
import { Controller, useForm } from "react-hook-form";
import InlineEditor from "../../../components/InlineEditor";
import { openChangePasswordModal } from "../../../components/UserProfileHeaderAction/UserProfileHeaderAction";
import { useAppContext } from "../../../store";

const openCreateUser = create(({ isOpen, onReject, onResolve }) => {
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      enable: true,
      recoveryBoyWalletLimit: 1000,
      role: "RECOVERY_BOY",
    },
  });
  const role = watch("role");
  const createUser = useCreate("users");
  const appContext = useAppContext();

  const { data: companies, isLoading } = useGetQueryWithPagination({
    entity: "companies",
    fetchingOptions: {
      enabled: appContext.user.role === "ADMIN",
    },
  });

  const onSubmit = async (data) => {
    const { error } = await createUser(data);
    if (!error) onResolve();
  };

  return (
    <Modal
      open={isOpen}
      onRequestClose={onReject}
      onRequestSubmit={async () => {
        handleSubmit(onSubmit)();
      }}
      modalHeading="Create User"
      primaryButtonText="Create"
      size="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <TextInput
            {...register("userName", { required: "Required" })}
            labelText="Username"
            placeholder="Login username"
          />
        </div>
        <div className="mb-2">
          <TextInput
            type="password"
            labelText="Password"
            {...register("password", { required: "Required" })}
            placeholder="Password for login"
          />
        </div>

        <div className="my-2">
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value, name } }) => {
              return appContext.user.role === "ADMIN" ? (
                <RadioButtonGroup
                  onChange={(e) => onChange(e)}
                  valueSelected={value}
                  name={name}
                  legendText="Role"
                >
                  <RadioButton labelText="Cashier" value="CASHIER" />
                  <RadioButton labelText="Recovery Boy" value="RECOVERY_BOY" />
                  <RadioButton labelText="Operator" value="OPERATOR" />
                  <RadioButton
                    labelText="General Manager"
                    value="GENERAL_MANAGER"
                  />
                </RadioButtonGroup>
              ) : (
                <RadioButtonGroup
                  onChange={(e) => onChange(e)}
                  valueSelected={value}
                  name={name}
                  legendText="Role"
                >
                  <RadioButton labelText="Cashier" value="CASHIER" />
                  <RadioButton labelText="Recovery Boy" value="RECOVERY_BOY" />
                  <RadioButton labelText="Operator" value="OPERATOR" />
                </RadioButtonGroup>
              );
            }}
          />
        </div>

        {role === "RECOVERY_BOY" && (
          <TextInput
            labelText="Wallet Limit"
            type="number"
            {...register("recoveryBoyWalletLimit", {
              required: "Required",
              valueAsNumber: true,
            })}
            disabled={role !== "RECOVERY_BOY"}
            placeholder="Wallet limit"
          />
        )}

        {appContext.user.role === "ADMIN" && (
          <div className="my-2">
            <FormGroup legendText="Company">
              <Controller
                control={control}
                name="companyId"
                render={({ field: { onChange, value } }) =>
                  isLoading ? (
                    "Loading Companies"
                  ) : (
                    <ComboBox
                      placeholder="Select Company"
                      items={companies?.result?.rows}
                      selectedItem={companies?.result?.rows?.find(
                        (company) => company.id === value
                      )}
                      itemToString={(item) => item?.name}
                      onChange={({ selectedItem }) =>
                        onChange(selectedItem?.id)
                      }
                    />
                  )
                }
              />
            </FormGroup>
          </div>
        )}

        <div className="mt-3">
          <Controller
            control={control}
            name="enable"
            render={({ field: { onChange, ref, value } }) => (
              <Toggle
                checked={value}
                labelText="Enabled"
                onChange={(e) => onChange(e.currentTarget.checked)}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  );
});

export default function UsersPage({
  title = "Users",
  description = "Manage all the users of the system",
  endpoint = "users",
}) {
  const [searchField, setSearchField, searchFieldDebounced] = useDebouncedState(
    "",
    1000
  );

  const updateRecoveryBoy = useUpdate(endpoint);

  const { data, isLoading, refetch, pagination, setPagination } =
    useGetQueryWithPagination({
      entity: endpoint,
      queryParams: {
        where: !!searchFieldDebounced
          ? {
              OR: {
                fullName: {
                  startsWith: searchFieldDebounced,
                },
              },
            }
          : undefined,
      },
      fetchingOptions: {
        select: (data) => {
          return data.result;
        },
      },
    });

  const rows = useMemo(() => {
    return data?.rows?.map((row) => ({
      id: row.id,
      fullName: row.fullName,
      userName: row.userName,
      role: row.role,
      company: row.company?.name,
      wallet: `Rs. ${Number(row.wallet).toLocaleString()}`,
      recoveryBoyWalletLimit:
        row.role !== "RECOVERY_BOY" ? (
          "N/A"
        ) : (
          <InlineEditor
            prefix="Rs. "
            onChange={(value) =>
              updateRecoveryBoy({
                id: row.id,
                recoveryBoyWalletLimit: +value.trim(),
              }).finally(() => refetch())
            }
            text={row.recoveryBoyWalletLimit}
          />
        ),
      enable: (
        <ToggleSingleLine
          checked={row.enable}
          onChange={async (e) => {
            await updateRecoveryBoy({
              id: row.id,
              enable: e.currentTarget.checked,
            });
            refetch();
          }}
        />
      ),
      changePassword: (
        <Button
          kind="ghost"
          onClick={() => {
            openChangePasswordModal({ id: row.id });
          }}
        >
          Change Password
        </Button>
      ),
    }));
  }, [data, refetch, updateRecoveryBoy]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && (
        <DataTable
          rows={rows}
          headers={[
            { header: "ID", key: "id" },
            { header: "fullName", key: "fullName" },
            { header: "userName", key: "userName" },
            { header: "Wallet", key: "wallet" },
            { header: "Company", key: "company" },
            { header: "role", key: "role" },
            {
              header: "recovery boy wallet limit",
              key: "recoveryBoyWalletLimit",
            },
            { header: "enable", key: "enable" },
            { header: "", key: "changePassword" },
          ]}
        >
          {({
            rows,
            headers,
            getHeaderProps,
            selectedRows,
            getTableProps,
            getToolbarProps,
            getBatchActionProps,
            getSelectionProps,
          }) => (
            <TableContainer
              title={title}
              className="border"
              description={description}
            >
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => setSearchField(e.target?.value)}
                  />
                  <Button
                    onClick={async () => {
                      await openCreateUser();
                      refetch();
                    }}
                  >
                    Create new...
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell) => {
                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <DefaultPagination
                pagination={pagination}
                setPagination={setPagination}
                count={data?.count}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}
