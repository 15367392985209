import AwaitableModal from "./AwaitableModal";

export async function confirmOperation(text, danger = false) {
  try {
    await AwaitableModal({
      isOpen: true,
      primaryButtonText: "Yes",
      secondaryButtonText: "No",
      danger,
      size: "xs",
      children: <p>{text}</p>,
    });

    return true;
  } catch (e) {
    return false;
  }
}
