import { Button, Modal } from "carbon-components-react";
import { useForm, FormProvider } from "react-hook-form";
import { create } from "react-modal-promise";

export default function FilterModal({
  filter,
  children,
  isOpen,
  onReject,
  onResolve,
}) {
  const formReturn = useForm({ defaultValues: filter });
  const { getValues } = formReturn;

  return (
    <Modal
      open={isOpen}
      onRequestClose={onReject}
      onRequestSubmit={() => {
        onResolve(getValues());
      }}
      primaryButtonText="Apply"
      size="xs"
    >
      <FormProvider {...formReturn}>{children}</FormProvider>
    </Modal>
  );
}

export const openFilterModal = create(FilterModal);
