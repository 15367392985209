import { useState } from "react";
// import { useDebouncedCallback } from "use-debounce";

export default function InlineEditor({ text, onChange, prefix }) {
  const [isEditMode, setEditModeBoolean] = useState(false);

  // const onChangeDebounded = useDebouncedCallback((value) => {
  //   onChange(value);
  // }, 1000);

  return (
    <span>
      {prefix}
      <span
        style={{ userSelect: "none" }}
        onDoubleClick={(ev) => {
          setEditModeBoolean(true);
        }}
        contentEditable={isEditMode}
        onKeyUp={(ev) => {
          if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            ev.preventDefault();
            setEditModeBoolean(false);
            onChange(ev.target.innerText);
          }
        }}
      >
        {text || "null"}
      </span>
    </span>
  );
}
