import axios from "axios";

export class ApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiError";
  }
}

export class UnauthenticatedError extends ApiError {
  constructor(message) {
    super(message);
    this.name = "UnauthenticatedError";
  }
}

export default class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
  }

  /**
   *
   * @param {AxiosRequestConfig} props
   * @returns
   */
  async request(props) {
    try {
      const result = await this.axios.request(props);
      return result;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError = e;

        if (axios.isCancel(axiosError)) {
          return;
        }

        if (axiosError.response) {
          const errorMessage = axiosError.response?.data.message;

          if (axiosError.response?.status === 401) {
            throw new UnauthenticatedError(errorMessage);
          }

          throw new ApiError(errorMessage);
        }
      }

      throw e;
    }
  }

  async login(userName, password) {
    const { data } = await this.request({
      url: "/auth/login",
      method: "post",
      data: {
        userName,
        password,
      },
    });

    if (data.status === 200) {
      this.loginWithToken(data.result.token);
      return data.result;
    }
  }

  loginWithToken(token) {
    this.axios.defaults.headers.authorization = `Bearer ${token}`;
  }

  async logout(user) {
    delete this.axios.defaults.headers.authorization;

    // TODO: invalidate refresh token
  }

  async changePassword(data, id) {
    const { data: response } = await this.request({
      url: `/change-password/${id ? id : ""}`,
      method: "put",
      data,
    });

    return response;
  }

  /**
   *
   * @param {PrismaFilter} params
   * @returns
   */
  async get(entity, params, options) {
    const { data } = await this.request({
      url: `/${entity}`,
      method: "get",
      params: params,
      ...options,
    });

    return data.result;
  }

  async create(entityName, body, options) {
    const { data } = await this.request({
      url: `/${entityName}`,
      method: "post",
      data: body,
      ...options,
    });

    return data;
  }

  async delete(entityName, ids) {
    const { data } = await this.request({
      url: `/${entityName}`,
      method: "delete",
      data: ids,
    });

    return data;
  }

  async update(entityName, body) {
    const { data } = await this.request({
      url: `/${entityName}`,
      method: "put",
      data: body,
    });

    return data;
  }

  /**
   * Assign some entity to some other entity
   * @param {string} entityName
   * @param {number} id
   * @param {string} withEntity
   * @param {object} body
   * @returns
   */
  async assign(entityName, id, withEntity, body) {
    const { data } = await this.request({
      url: `/${entityName}/${id}/${withEntity}`,
      method: "post",
      data: body,
    });

    return data;
  }

  /**
   * Assign some entity to some other entity
   * @param {string} entityName
   * @param {number} id
   * @param {string} withEntity
   * @param {object} body
   * @returns
   */
  async deassign(entityName, id, withEntity, body) {
    const { data } = await this.request({
      url: `/${entityName}/${id}/${withEntity}`,
      method: "delete",
      data: body,
    });

    return data;
  }

  async receiveMoneyFromRecoveryBoy(id, amount) {
    const { data } = await this.request({
      url: `/recoveryboys/receive/${id}`,
      method: "post",
      data: { amount },
    });

    return data;
  }

  async getCompanyCashierForGM() {
    const { data } = await this.request({
      url: `/getCompanyCashiersList`,
      method: "get",
    });
    return data;
  }

  async getReceiptImage(spendingId) {
    const res = await this.request({
      url: `/spendings/${spendingId}/receipt-image`,
      responseType: "blob",
    });

    return res.data;
  }

  async getPdfTransactions(where) {
    const { data } = await this.request({
      url: `/transactions/pdf`,
      method: "post",
      data: where,
      responseType: "blob",
    });

    return data;
  }
}
