import { useAlert } from "react-alert";

import { UnauthenticatedError } from "../api/Api";
import { useAppContext } from "../store";

export default function useApiHandler(props) {
  const appContext = useAppContext();
  const alert = useAlert();

  return async (...args) => {
    let result, error;
    const { alerts = false, throwError = false } = props.config || {};

    try {
      result = await props.function.call(appContext.api, ...args);
      alerts && result?.message && alert.success(result.message);
    } catch (e) {
      if (e instanceof UnauthenticatedError) {
        await appContext.logout();
      }

      alerts && alert.error(e.message);

      console.error(e);

      error = e;

      if (throwError) {
        throw e;
      }
    }

    return { result, error };
  };
}
